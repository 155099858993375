import { ExternalLinkProps } from "../../helpers/types";
import { FormattedMessage } from "react-intl";
import React from "react";

const ExternalLink = (props: ExternalLinkProps) => {
  const { link, textKey } = props;
  return (
    <a
      href={link}
      target={"_blank"}
      rel={"noreferrer"}
      className={"text-blue-500"}
    >
      <FormattedMessage id={textKey} />
    </a>
  );
};

export default ExternalLink;
