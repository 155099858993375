import React from "react";
import FooterSection from "../components/PageSection/FooterSection";
import JosefinSlabText from "../components/text/JosefinSlabText";

const Footer = () => {
  return (
    <FooterSection>
      {/*<div className="flex-1 flex-col ">*/}
      {/*  <JosefinSlabText>footer.imprint</JosefinSlabText>*/}
      {/*  <JosefinSlabText>footer.privacy</JosefinSlabText>*/}
      {/*</div>*/}
      <div className="flex-1 flex-col sm:pl-0 lg:pl-32 justify-center text-center">
        <JosefinSlabText>footer.concept</JosefinSlabText>
      </div>
      <div className="flex-1 flex-col">
        <JosefinSlabText>footer.technical</JosefinSlabText>
      </div>
      <div className="flex-1 flex-col">
        <JosefinSlabText>footer.photo</JosefinSlabText>
      </div>
    </FooterSection>
  );
};

export default Footer;
