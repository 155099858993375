import React from "react";
import GreatVibesText from "../../../components/text/GreatVibesText";
import { AvailableInformationPages } from "../../../helpers/helpers";
import NormalText from "../../../components/text/NormalText";

const TravelToIndia = () => {
  const page = AvailableInformationPages.travelToIndia;
  return (
    <>
      <GreatVibesText>{page.textKeyPrefix + page.title}</GreatVibesText>
      <div className="text-justify">
        <NormalText key={"line1"}>{page.textKeyPrefix + "line1"}</NormalText>
        <NormalText key={"line2"}>{page.textKeyPrefix + "line2"}</NormalText>
        <NormalText key={"line3"}>{page.textKeyPrefix + "line3"}</NormalText>
        <NormalText key={"line4"}>{page.textKeyPrefix + "line4"}</NormalText>
        <NormalText key={"line5"}>{page.textKeyPrefix + "line5"}</NormalText>
        <NormalText key={"line6"}>{page.textKeyPrefix + "line6"}</NormalText>
        <NormalText key={"line7"}>{page.textKeyPrefix + "line7"}</NormalText>
        <NormalText key={"line8"}>{page.textKeyPrefix + "line8"}</NormalText>
        <NormalText key={"line9"}>{page.textKeyPrefix + "line9"}</NormalText>
        <NormalText key={"line10"}>{page.textKeyPrefix + "line10"}</NormalText>
      </div>
    </>
  );
};

export default TravelToIndia;
