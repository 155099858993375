import React from "react";
import SectionTextBlock from "../components/SectionTextBlock";
import GreatVibesText from "../components/text/GreatVibesText";
import NormalText from "../components/text/NormalText";
import PageSection from "../components/PageSection";
import {
  AvailableInformationPages,
  AvailableMenuItems,
} from "../helpers/helpers";
import { PageProps } from "../helpers/types";
import Card from "../components/Card";
import travel from "../assets/images/aircraft.svg";
import stay from "../assets/images/stay.svg";
import weddings from "../assets/images/wedding.svg";
import corona from "../assets/images/corona.svg";
import LinkHolder from "../components/LinkHolder";

const IndianWedding = (props: PageProps) => {
  return (
    <PageSection id={AvailableMenuItems.india.name}>
      <SectionTextBlock
        menuItem={AvailableMenuItems.india}
        onScrollChange={props.onScrollChange}
      >
        <GreatVibesText>india.title</GreatVibesText>
        <NormalText />
        <NormalText>india.line1</NormalText>
        <NormalText />
      </SectionTextBlock>
      <LinkHolder>
        <div className="flex flex-row">
          <Card
            header="india.cards.headers.travel"
            image={travel}
            link={AvailableInformationPages.travelToIndia.url}
          />
          <Card
            header="india.cards.headers.accommodation"
            image={stay}
            link={AvailableInformationPages.whereToStay.url}
          />
          <Card
            header="india.cards.headers.weddings"
            className="hidden md:inline-flex lg:hidden"
            image={weddings}
            link={AvailableInformationPages.indianWedding.url}
          />
          <Card
            header="india.cards.headers.corona"
            className="hidden md:inline-flex lg:hidden"
            image={corona}
            link={AvailableInformationPages.coronaInIndia.url}
          />
        </div>
        {/* Second Column*/}
        <div className="flex flex-row">
          <Card
            header="india.cards.headers.weddings"
            className="md:hidden lg:inline-flex"
            image={weddings}
            link={AvailableInformationPages.indianWedding.url}
          />
          <Card
            header="india.cards.headers.corona"
            className="md:hidden lg:inline-flex"
            image={corona}
            link={AvailableInformationPages.coronaInIndia.url}
          />
        </div>
      </LinkHolder>
    </PageSection>
  );
};

export default IndianWedding;
