import { motion } from "framer-motion";
import React from "react";
import ganesha from "../../assets/images/ganesha-new.png";
import { GenericProps } from "../../helpers/types";

interface SidebarGaneshaProps extends GenericProps {
  animate: boolean;
}
const SidebarGanesha = (props: SidebarGaneshaProps) => {
  return (
    <div
      className={
        "flex flex-col justify-center bg-mainRed fixed " +
        "w-screen lg:w-2/5 xl:w-1/4 h-56 md:h-1/3 lg:h-screen  md:bg-points-left-sm lg:bg-points-left bg-repeat-y bg-left" +
        (props.className ? " " + props.className : "")
      }
    >
      <motion.img
        animate={{
          scale: [1, 1.2, 1],
        }}
        transition={{
          duration: 2,
        }}
        src={ganesha}
        className="object-scale-down  h-1/3 lg:h-1/4"
        alt="ganesha"
      />
      {/*<img src={ganesha} className="object-scale-down  h-1/4" alt="ganesha" />*/}
      {/*<img*/}
      {/*  src={ganeshaMedium}*/}
      {/*  className="object-scale-down pt-14 pb-4 hidden md:inline-flex lg:hidden h-1/3"*/}
      {/*  alt="ganesha"*/}
      {/*/>*/}
      {/*<img*/}
      {/*  src={ganeshaSmall}*/}
      {/*  className="object-scale-down visible md:hidden h-1/3 pt-14 pb-4"*/}
      {/*  alt="ganesha"*/}
      {/*/>*/}
    </div>
  );
};

export default SidebarGanesha;
