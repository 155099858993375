import React, { Suspense, useState } from "react";
import "./App.css";
import Header from "./components/Header";
import Home from "./pages/Home";
import SaveTheDate from "./pages/SaveTheDate";
import SidebarGanesha from "./components/ganesha/SidebarGanesha";
import IndianWedding from "./pages/IndianWedding";
import GermanWedding from "./pages/GermanWedding";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import {
  AvailableInformationPages,
  AvailableMenuItems,
  findLocaleName,
} from "./helpers/helpers";
import Footer from "./pages/Footer";
import Information from "./pages/Information";
import { LOCALES, messages } from "./i18n/messages";
import { IntlProvider } from "react-intl";

function App() {
  const [animateGanesha, setAnimateGanesha] = useState(true);
  const [locale, setLocale] = useState(findLocaleName(navigator.language));
  const onSectionChange = () => {
    setAnimateGanesha(!animateGanesha);
  };

  const changeAppLanguage = (currentLocale: string): void => {
    console.log("changeAppLanguage, Line 33 : currentLocale", currentLocale);
    if (findLocaleName(currentLocale) === LOCALES.ENGLISH) {
      setLocale(LOCALES.GERMAN);
    } else if (findLocaleName(currentLocale) === LOCALES.GERMAN) {
      setLocale(LOCALES.ENGLISH);
    }
  };
  return (
    <IntlProvider
      locale={locale}
      defaultLocale={LOCALES.ENGLISH}
      messages={messages[locale]}
    >
      <Suspense fallback={<SidebarGanesha animate />}>
        <div className="bg-mainBackground text-center flex flex-1 flex-col lg:flex-row">
          <SidebarGanesha animate={animateGanesha} />
          <Router>
            <Switch>
              <Route path={"/"} exact>
                <Header handleChangeLanguage={changeAppLanguage} />
              </Route>
              <Route>
                <Header
                  isSecondaryHeader
                  handleChangeLanguage={changeAppLanguage}
                />
              </Route>
            </Switch>
            <div className="flex flex-col">
              <Switch>
                <Route path={"/"} exact>
                  <>
                    <Home onScrollChange={onSectionChange} />
                    <SaveTheDate onScrollChange={onSectionChange} />
                    <IndianWedding onScrollChange={onSectionChange} />
                    <GermanWedding onScrollChange={onSectionChange} />
                  </>
                </Route>
                {AvailableInformationPages.getAllAsArray().map((page) => {
                  return (
                    <Route key={page.url} path={page.url}>
                      <Information page={page} />
                    </Route>
                  );
                })}
              </Switch>
              <Redirect to={AvailableMenuItems.home.url} from={"/"} />
              <Footer />
            </div>
          </Router>
        </div>
      </Suspense>
    </IntlProvider>
  );
}

export default App;
