import React from "react";
import { GenericProps } from "../../helpers/types";

const FooterSection = (props: GenericProps) => {
  const { children } = props;
  return (
    <section className="flex flex-row justify-center md:ml-0 lg:ml-80 h-20 bg-footerBackground p-2 ">
      {children ? children : null}
    </section>
  );
};

export default FooterSection;
