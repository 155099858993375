import React from "react";
import { GenericProps } from "../../helpers/types";
import JosefinSlabText from "./JosefinSlabText";

const NormalText = (props: GenericProps) => {
  const { className } = props;
  return (
    <JosefinSlabText
      className={
        "mt-4 text-lg md:text-2xl" + (className ? " " + className : "")
      }
    >
      {props.children}
    </JosefinSlabText>
  );
};

export default NormalText;
