import React from "react";
import { GenericProps } from "../../helpers/types";

const LinkHolder = (props: GenericProps) => {
  const { className } = props;

  const customClasses = className ? className : "";
  return (
    <div
      className={
        "pl-5 md:pl-10 lg:pl-32 pr-5 lg:pr-10 flex flex-col text-center " +
        customClasses
      }
    >
      {props.children}
    </div>
  );
};

export default LinkHolder;
