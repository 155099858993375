import React from "react";
import SectionTextBlock from "../components/SectionTextBlock";
import GreatVibesText from "../components/text/GreatVibesText";
import NormalText from "../components/text/NormalText";
import PageSection from "../components/PageSection";
import { AvailableMenuItems } from "../helpers/helpers";
import { PageProps } from "../helpers/types";

const Home = (props: PageProps) => {
  return (
    <PageSection id={AvailableMenuItems.home.name}>
      <SectionTextBlock
        menuItem={AvailableMenuItems.home}
        onScrollChange={props.onScrollChange}
      >
        <GreatVibesText>home.title</GreatVibesText>

        <NormalText>home.subtitle</NormalText>
        <NormalText>home.line2</NormalText>
        <NormalText>home.line3</NormalText>
      </SectionTextBlock>
    </PageSection>
  );
};

export default Home;
