import React from "react";
import { GenericProps } from "../../helpers/types";

interface PageSectionProps extends GenericProps {
  id: string;
}

const PageSection = (props: PageSectionProps) => {
  const { id, children } = props;
  return (
    <section
      id={id}
      className="flex flex-col text-center justify-center md:ml-0 lg:ml-96 h-screen"
    >
      {children ? children : null}
    </section>
  );
};

export default PageSection;
