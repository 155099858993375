import React from "react";
import SectionTextBlock from "../components/SectionTextBlock";
import GreatVibesText from "../components/text/GreatVibesText";
import NormalText from "../components/text/NormalText";
import PageSection from "../components/PageSection";
import { AvailableMenuItems } from "../helpers/helpers";
import { PageProps } from "../helpers/types";

const SaveTheDate = (props: PageProps) => {
  return (
    <PageSection id={AvailableMenuItems.saveTheDate.name}>
      <SectionTextBlock
        menuItem={AvailableMenuItems.saveTheDate}
        onScrollChange={props.onScrollChange}
      >
        <GreatVibesText>save_the_date.title</GreatVibesText>
        <NormalText>save_the_date.line1</NormalText>
        <NormalText>save_the_date.line2</NormalText>
        <NormalText />
        <GreatVibesText>save_the_date.line3</GreatVibesText>
        <NormalText>save_the_date.line4</NormalText>
        <NormalText>save_the_date.line5</NormalText>
        <NormalText>save_the_date.line6</NormalText>
        <NormalText>save_the_date.line7</NormalText>
        <NormalText />
        <GreatVibesText>save_the_date.line8</GreatVibesText>
        <NormalText>save_the_date.line9</NormalText>
      </SectionTextBlock>
    </PageSection>
  );
};

export default SaveTheDate;
