import React from "react";
import { GenericProps } from "../../helpers/types";
import { FormattedMessage } from "react-intl";
import ExternalLink from "./ExternalLink";

const values: Record<string, React.ReactNode> = {
  bonnCoronaNumbers: (
    <ExternalLink
      link={
        "https://www.bonn.de/themen-entdecken/gesundheit-verbraucherschutz/corona-zahlen.php"
      }
      textKey={"links.bonnCoronaNumbers"}
    />
  ),
  indiaVaccinationsInADay: (
    <ExternalLink
      link={
        "https://www.hindustantimes.com/india-news/india-administers-record-10-million-vaccine-doses-in-a-day-101630091551169.html"
      }
      textKey={"links.indiaVaccinationsInADay"}
    />
  ),
  aerzteblattMagazine: (
    <ExternalLink
      link={
        "https://www.aerzteblatt.de/nachrichten/125771/Corona-Zwei-Drittel-der-Menschen-in-Indien-haben-Antikoerper"
      }
      textKey={"links.aerzteblattMagazine"}
    />
  ),
  ardText: (
    <ExternalLink
      link={"https://www.ard-text.de/mobil/152"}
      textKey={"links.ardText"}
    />
  ),
  hepatitisAndTyphoid: (
    <strong>
      <FormattedMessage id={"bold.hepatitisAndTyphoid"} />{" "}
    </strong>
  ),
  indiaVisaLink: (
    <ExternalLink
      link={"https://indianvisaonline.gov.in/"}
      textKey={"links.indiaVisaLink"}
    />
  ),
  offizielleZahlenIndien: (
    <ExternalLink
      link={"https://dashboard.cowin.gov.in/"}
      textKey={"links.offizielleZahlenIndien"}
    />
  ),
};

const JosefinSlabText = (props: GenericProps) => {
  return (
    <p
      className={
        "font-JosefinSlab  font-semibold  " +
        (props.className ? props.className : "")
      }
    >
      {props.children ? (
        <FormattedMessage values={values} id={props.children} />
      ) : null}
    </p>
  );
};

export default JosefinSlabText;
