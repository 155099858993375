import React from "react";
import { PageProps } from "../helpers/types";
import VisibilitySensor from "react-visibility-sensor";
import { MenuItem } from "../helpers/helpers";
import { useHistory } from "react-router-dom";

interface SectionTextBlockProps extends PageProps {
  menuItem: MenuItem;
}

const SectionTextBlock = (props: SectionTextBlockProps) => {
  const { className, menuItem } = props;
  const history = useHistory();

  const onVisibilityChange = (isVisible: boolean) => {
    if (isVisible) {
      history.replace(menuItem.url);
      props.onScrollChange();
    }
  };

  const customClasses = className ? className : "";

  return (
    <VisibilitySensor onChange={onVisibilityChange}>
      <div
        className={
          "pl-5 md:pl-10 lg:pl-32 pr-5 lg:pr-10 flex flex-col text-center pt-32 md:pt-0" +
          customClasses
        }
      >
        {props.children}
      </div>
    </VisibilitySensor>
  );
};

export default SectionTextBlock;
