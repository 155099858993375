export const LOCALES = {
  ENGLISH: "en",
  GERMAN: "de",
};
export const messages: Record<string, Record<string, string>> = {
  [LOCALES.ENGLISH]: {
    "languages.en": "En",
    "languages.de": "De",
    "menu.home": "Home",
    "menu.save_the_date": "Date",
    "menu.india": "India",
    "menu.germany": "Germany",

    "home.title": "Maria & Varij are getting married",
    "home.subtitle":
      "We would like to celebrate our wedding together with you!",
    "home.line2":
      "And as such an Indian-German wedding is very exciting, we are happy to share the most useful information around it here on this website.",
    "home.line3":
      "Especially with regards to India and the COVID situation, we collected recent news for you.",

    "save_the_date.title": "Save the Date",
    "save_the_date.line1":
      "We did the civil marriage only with our closest friends. The Indian Wedding and the ceremony in Germany are still coming up, and we would like to celebrate those with you together!",
    "save_the_date.line2": "Please, keep",
    "save_the_date.line3": "November 09-11th, 2021",
    "save_the_date.line4": "free.",
    "save_the_date.line5":
      "We will provide all information about the location and programme about our Indian wedding soon.",
    "save_the_date.line6":
      "We will only send the invitation cards, once the entry to India is possible again for non-Indian citizens.",
    "save_the_date.line7": "The church wedding in Germany is planned for ",
    "save_the_date.line8": "July 09th, 2022",
    "save_the_date.line9":
      "We will share all information by the end of this year.",

    "india.title": "The Indian Wedding",
    "india.line1": "More Information coming soon...",
    "india.cards.headers.travel": "How do I get to India?",
    "india.cards.headers.accommodation": "Where can I stay in India?",
    "india.cards.headers.weddings": "What is an Indian Wedding?",
    "india.cards.headers.corona": "What about Corona in India?",

    "germany.title": "The German Wedding",
    "germany.line1": "More Information coming soon...",

    "footer.imprint": "Imprint",
    "footer.concept": "Concept: Maria ",
    "footer.technical": "Development: Varij",
    "footer.photo": "Images: zazzle.com,undraw.co",
    "footer.privacy": "Privacy Policy",

    "information.india.travel.title": "How do I get to India?",
    "information.india.travel.line1":
      "Of course you can go by bike or tractor, but honestly, going by plane would be the most comfortable and fastest way.",
    "information.india.travel.line2":
      "Departing from Germany, connections from Frankfurt or Munich, operated by Lufthansa or AirIndia will be the best options. Your destination will be Chandigarh (IXC). Most probably, you will find a connection via New Delhi or Mumbai. By booking an Emirates flight, you will get a stop in Dubai. The flight from Germany to India will take about 8 hours and Chandigarh is again one hour from Delhi by plane.",
    "information.india.travel.line3":
      "Those of you coming from the Netherlands or Austria, please check on you own ;)",
    "information.india.travel.line4":
      "No matter which mean of transport or which route you choose, the most important thing to do before your departure, is to get a visa granted. You can apply for your visa, once COVID is done, online on this website of the Indian government: {indiaVisaLink}",
    "information.india.travel.line5":
      "The most uncomplicated visa will be the e-visa for tourists. You just need to fill out the form, scan your passport (only the page that shows your personal information) and upload a photo of yourself. No need to go to an Embassy or consulate in person or to send your passport to any authority. Once your application is submitted, it will take approx. 4 days before you get the approval (via e-mail and online available). Print it out and show it to the immigration officer. You get your stamp. Done.",
    "information.india.travel.line6":
      "A new passport costs around 60€ (in Germany). It will take up to four weeks before you can go and pick it up from your local authority.",
    "information.india.travel.line7":
      "The whole world is talking about vaccination, so we also want to give a few hints: There are no mandatory vaccinations required to enter India. A covid vaccination is also not required. The German Federal Foreign office however recommends a few vaccinations before traveling to India. These include next to the “standard” vaccinations like measles mumps rubella, tetanus and polio {hepatitisAndTyphoid}. In case you plan to stay longer, a vaccination against rabies, Hepatitis B and Japanese Encephalitis is also recommended. But this is only necessary, if you are going to camp in the jungle, do a safari or something else with animals.",
    "information.india.travel.line8":
      'The app "Sicher reisen" (also available in English) by the Federal Foreign office, provides a good overview about all travel restrictions and information. You will find regular updates regarding the Covid-situation by push notifications.',
    "information.india.travel.line9":
      "What we would always recommend, is an international health care insurance, that explicitly covers covid.",
    "information.india.travel.line10":
      "In case you have any questions, doubts or difficulties, just drop a message to Maria or Varij.",

    "information.india.stay.title": "Where can I stay in India",
    "information.india.stay.line1":
      "From November 8th-12th, 2021, we will reserve a few rooms at some hotels near to the wedding venue, which you can book by yourself, mentioning that you are guests invited by the Kapil’s.",
    "information.india.stay.line2": "The costs per room will be around 45€.",
    "information.india.stay.line3":
      "You want to book a cheaper stay or are looking for the more Indian experience? Just call Varij!",
    "information.india.stay.line4": "",
    "information.india.stay.line5":
      "(Information on which hotels are booked is coming soon...)",
    "information.india.stay.line6":
      "You can book an airport pick up with the hotel (very expensive!) or take a cab at the airport of Chandigarh. The ride to the hotel will take about 3 hours. Please, tell us your time of arrival and we will help you to organize your airport transfer",
    "information.india.stay.line7":
      "The wedding itself will not take place in the hotel. But no worries, we will take care of your travel to the wedding venue and back to your hotels",

    "information.india.wedding.title": "What is an Indian Wedding?",
    "information.india.wedding.line1":
      "Indian weddings are known in Europe as big parties with hundreds of guests taking place for multiple days. What sounds very exciting to a few of you might be more scary for others.",
    "information.india.wedding.line2":
      "To avoid any uncertainties and fears, we collected the most relevant information for you. You can also read about the Indian culture in general (only the basics) and a few Do’s and Don’ts. You want to impress the Indian family by a few words of Hindi? We have some vocabulary for you.",
    "information.india.wedding.line3": "(More Information coming soon... )",

    "information.india.corona.title": "What about Corona in India?",
    "information.india.corona.line1":
      "The heavy third wave in April and May is -thank God- broken and the daily new cases are now on a much lower level.",
    "information.india.corona.line2":
      "Currently, there are around 45,000 new cases per day. That sounds a lot, but given a population of more than 1,3 billion people, the figure is rather small. The new cases are reported mostly (70%) from Kerala ({ardText}), a state in the south-east of India. Our Wedding will take place in the north of India. In Punjab, the state where we are getting married, are currently 405 active cases of corona reported. In this state, there live 28 million people, so the ratio of corona cases is almost insignificant. Just a quick comparison: Bonn, with only 300,000 inhabitants, is currently dealing with 548 active cases ({bonnCoronaNumbers}, 29.08.2021)!",
    "information.india.corona.line3":
      "According to the German “Ärzteblatt”, two third of the Indian population do have antibodies. The magazine is referring to an investigation contracted by the Indian government with samples of more than 36,000 people in June and July 2021 ({aerzteblattMagazine}, 21.07.2021). These figures are of course given by the huge number of people recovered from COVID, but also by the large vaccination programme of the country. As an example: On August 27th, more than 10 million people got a vaccination on only one day ({indiaVaccinationsInADay}, 29.08.2021). For latest numbers and statistics about cases and vaccinations, please have a look at the website of the Indian Ministry of Health {offizielleZahlenIndien}.",
    "information.india.corona.line4":
      "And what does that mean for our wedding? There will be a lot of rituals outside. However, please take your masks with you.",
    "information.india.corona.line5":
      "The ones that are fully vaccinated, do not need to fear a quarantine on their return to Germany (we don’t know about other countries), as India is not marked a “Virus Variant Country” anymore.",

    "links.bonnCoronaNumbers": "Corona numbers in Bonn",
    "links.indiaVaccinationsInADay": "10 million Vaccinations, India",
    "links.aerzteblattMagazine": "Link",
    "links.ardText": "Tagesschau",
    "links.indiaVisaLink": "Link",
    "links.offizielleZahlenIndien": "official numbers India",

    "bold.hepatitisAndTyphoid": "Hepatitis A and Typhoid",
  },
  [LOCALES.GERMAN]: {
    "languages.en": "En",
    "languages.de": "De",

    "menu.home": "Home",
    "menu.save_the_date": "Datum",
    "menu.india": "Indien",
    "menu.germany": "Deutschland",

    "home.title": "Maria & Varij heiraten",
    "home.subtitle":
      "Bei diesem großen Fest wollen wir euch gerne dabei haben! ",
    "home.line2":
      "Und da eine deutsch-indische Hochzeit sehr aufregend ist, haben wir euch die wichtigsten Informationen hier zusammengestellt.",
    "home.line3":
      "Zu Indien haben wir neue Infos, besonders zu Corona für euch gesammelt.",

    "save_the_date.title": "Save the Date",
    "save_the_date.line1":
      "Die standesamtliche Hochzeit haben wir in einem sehr kleinen Rahmen gefeiert. Die indische Feier und auch die kirchliche Trauung in Deutschland stehen aber noch an.",
    "save_the_date.line2": "Bitte haltet euch den",
    "save_the_date.line3": "09. - 11. November 2021",
    "save_the_date.line4": "frei.",
    "save_the_date.line5":
      "Alle weiteren Informationen zum Ort und dem Ablauf unserer indischen Hochzeit werden wir euch bald mitteilen.",
    "save_the_date.line6":
      "Wir werden die Einladungen erste versenden, wenn eine Einreise nach Indien für Nicht-Inder wieder möglich ist.",
    "save_the_date.line7": "Die kirchliche Trauung wollen wir mit euch am ",
    "save_the_date.line8": "09. Juli 2022",
    "save_the_date.line9":
      "feiern. Alle weiteren Infos folgen Ende des Jahres.",

    "india.title": "Die indische Hochzeit",
    "india.line1": "Bald seht ihr hier mehr Infos...",
    "india.cards.headers.travel": "Wie komme ich nach Indien?",
    "india.cards.headers.accommodation": "Wo kann ich übernachten?",
    "india.cards.headers.weddings": "Wie läuft eine indische Hochzeit ab?",
    "india.cards.headers.corona": "Und was ist mit Corona in Indien?",

    "germany.title": "Die deutsche Hochzeit",
    "germany.line1": "Bald seht ihr hier mehr Infos...",

    "footer.imprint": "Impressum",
    "footer.concept": "Konzept: Maria ",
    "footer.technical": "Entwicklung: Varij",
    "footer.photo": "Bildquelle: zazzle.com,undraw.co",
    "footer.privacy": "Datenschutz",

    "information.india.travel.title": "Wie komme ich nach Indien?",
    "information.india.travel.line1":
      "Natürlich kann man mit dem Motorrad oder dem Traktor nach Indien fahren, aber bequemer und vor allem schneller ist es dann doch mit dem Flugzeug.",
    "information.india.travel.line2":
      "Die besten Verbindungen aus Deutschland findet ihr von Frankfurt und München mit Lufthansa oder AirIndia. Euer Zielflughafen ist Chandigarh (IXC). Die Airlines schlagen euch vermutlich eine Verbindung über Delhi oder Mumbai vor. Wenn ihr mit Emirates fliegt, habt ihr vermutlich eine Zwischenlandung in Dubai.",
    "information.india.travel.line3":
      "Der Flug von Deutschland nach Indien dauert ungefähr 8 Stunden. Chandigarh ist eine Flugstunde von Neu Delhi entfernt.",
    "information.india.travel.line4":
      "Egal welches Verkehrsmittel und welche Route, wichtig ist, dass ihr vor Abreise euer Visum habt. Euer Visum könnt ihr ganz bequem, sobald Corona vorbei ist, online beantragen. Über diese Website kommt ihr zum Visa-Service der indischen Regierung: {indiaVisaLink}",
    "information.india.travel.line5":
      "Das einfachste ist, ein e-Visum für Touristen zu beantragen. Hierfür müsst ihr nur das Formular ausfüllen, euren Reisepass einscannen (nur die Seite mit den Angaben zu eurer Person) und ein Passbild hochladen. Ihr müsst also nicht persönlich zur Botschaft gehen oder euren Pass einschicken. Wenn ihr den Antrag abgeschickt habt, dann dauert es ungefähr vier Tage bis ihr euer Visum bekommt (per E-Mail und online abrufbar). Das müsst ihr ausdrucken und bei der Einreise vorzeigen. Dann kommt der Stempel in den Pass. Fertig.",
    "information.india.travel.line6":
      "Einen Reisepass zu beantragen kostet derzeit 60€. Es dauert ungefähr vier Wochen bis ihr den Pass bei der Behörde abholen könnt.",
    "information.india.travel.line7":
      "Die ganze Welt redet vom Impfen, daher auch von uns noch ein paar Hinweise: Es sind keine Impfungen zwingend erforderlich, um nach Indien einreisen zu dürfen. Auch eine Covid-Impfung ist keine Pflicht. Das Auswärtige Amt empfiehlt jedoch einige Impfungen für eine Indienreise. Diese sind neben den „Standard“-Impfungen wie Masern, Mumps und Röteln, Tetanus und Polio {hepatitisAndTyphoid}. Wenn ihr vorhabt länger in Indien zu bleiben, dann werden außerdem auch Tollwut, Hepatitis B und Japanische Enzephalitis empfohlen, das aber auch nur wenn ihr vorhabt im Dschungel zu campen, auf Safari zu gehen oder irgendetwas mit Tieren zu tun.",
    "information.india.travel.line8":
      'Einen guten Überblick über alle Reisebestimmungen und -informationen bietet die App "Sicher reisen" vom Auswärtigen Amt. Dort gibt es auch regelmäßig Updates zur Coronalage.',
    "information.india.travel.line9":
      "Immer zu empfehlen ist allerdings eine international Reisekrankenversicherung, die auch explizit bei Covid greift.",
    "information.india.travel.line10":
      "Wenn ihr Fragen oder Schwierigkeiten habt oder euch unsicher seid, meldet euch einfach bei Maria oder Varij.",

    "information.india.stay.title": "Wo kann ich übernachten?",
    "information.india.stay.line1":
      "Wir werden vom 08.-12.11.2021 einige Zimmer für euch reservieren, die ihr selbst buchen könnt. Gebt bei der Buchung an, dass ihr zur Hochzeitsfeier von Familie Kapil kommen wollt.",
    "information.india.stay.line2":
      "Ihr könnt mit ungefähr 45€ pro Nacht und Zimmer rechnen.",
    "information.india.stay.line3":
      "Wer billiger unterkommen möchte und eine indischere Erfahrung machen möchte, der kann sich bei Varij melden ;)",
    "information.india.stay.line4": "",
    "information.india.stay.line5": "(Bald seht ihr Infos über Hotels...)",
    "information.india.stay.line6":
      "Ihr könnt über das Hotel auch einen Airport-Pick-Up buchen (teuer!) oder ein Taxi am Flughafen Chandigarh nehmen. Die Fahrt dauert ungefähr 3 Stunden. Bitte teilt uns mit, wann ihr in Chandigarh ankommt, dann helfen wir euch gerne mit dem Flughafen Transfer.",
    "information.india.stay.line7":
      "Die Hochzeit selbst wird nicht in diesem Hotel stattfinden. Aber keine Sorge, wir werden dafür sorgen, dass ihr alle sicher vom Hotel zur Hochzeits-Location und wieder zurück kommt :)",

    "information.india.wedding.title": "Wie läuft eine indische Hochzeit ab?",
    "information.india.wedding.line1":
      "Indische Hochzeiten sind in Deutschland bekannt als große, mehrtägige Feste mit mehreren Hundert Gästen. Was für den einen sehr aufregend und spannend klingt, kann bei dem anderen vielleicht auch eher befremdlich wirken oder sogar Unsicherheit und Angst hervorrufen. ",
    "information.india.wedding.line2":
      "Das wollen wir natürlich vermeiden und haben euch hier das wichtigste zusammengestellt. Einen Guide über die indische Kultur im Allgemeinen (wirklich nur die Basics) und ein paar Dos und Don’ts haben wir auch für euch gesammelt. Wer die indische Verwandtschaft mit ein paar Wörtern Hindi überraschen möchte, der kann sich hier umschauen.",
    "information.india.wedding.line3": "(Bald seht ihr hier mehr Infos... )",

    "information.india.corona.title": "Und was ist mit Corona in Indien?",
    "information.india.corona.line1":
      "Die heftige dritte Welle von April und Mai ist in Indien Gott sei Dank gebrochen und die Fallzahlen haben sich wieder auf einem niedrigeren Niveau eingependelt.",
    "information.india.corona.line2":
      "Aktuell gibt es in Indien insgesamt ca. 45.000 neue Corona Fälle pro Tag. Das klingt viel, aber bei einer Bevölkerung von mehr als 1,3 Milliarden Menschen ist die Zahl recht niedrig. Die neuen Fälle treten vor allem im süd-westlichen Bundesstaat Kerala auf (70%) ({ardText}). Unsere Hochzeit wird aber ganz im Norden Indien stattfinden. In Punjab, also in dem Bundesstaat in dem wir feiern, sind aktuell 405 aktive Fälle aktiv. Wohl gemerkt leben in diesem Bundesstaat 28 Millionen Menschen! Die Inzidenzzahlen sind also verschwindend gering. Im Vergleich: In Bonn leben 300.000 Menschen und aktuell sind 548 Menschen mit Corona infiziert ({bonnCoronaNumbers}, 29.08.2021)!",
    "information.india.corona.line3":
      "Laut dem deutschen Ärzteblatt haben zwei Drittel der indischen Bevölkerung Antikörper. Das Ärzteblatt beruft sich dabei auf eine Untersuchung im Auftrag der indischen Regierung mit Proben von mehr als 36.000 Menschen vom Juni und Juli ({aerzteblattMagazine}, 21.07.2021). Diese Zahlen berufen sich natürlich einerseits auf die Genesenen, andererseits läuft in Indien eine der größten Impfkampagnen. So wurden zum Beispiel am 27. August an nur einem Tag mehr als 10 Millionen Impfdosen verabreicht ({indiaVaccinationsInADay}, 29.08.2021). Tagesaktuelle Infos über die Fall- und Impfzahlen aus Indien gibt es beim Gesundheitsministerium {offizielleZahlenIndien}.",
    "information.india.corona.line4":
      "Und was bedeutet das für unsere Hochzeit? Viele Teile der Feier werden im Freien stattfinden. Ansonsten denkt bitte an eure Masken, eure Impfausweise und ein paar Corona-Selbsttests.",
    "information.india.corona.line5":
      "Wer vollständig geimpft ist, braucht auch keine Quarantäne nach der Rückreise zu befürchten, da Indien nicht mehr als Virusvariantengebiet gilt.",

    "links.bonnCoronaNumbers": "Corona Zahlen in Bonn",
    "links.indiaVaccinationsInADay": "10 Millionen Impfungen in Indien",
    "links.aerzteblattMagazine": "Ärzteblatt",
    "links.ardText": "Tagesschau",
    "links.indiaVisaLink": "Link",
    "links.offizielleZahlenIndien": "offizielle Zahlen Indien",

    "bold.hepatitisAndTyphoid": "Hepatitis A und Typhus",
  },
};
