import React, { useEffect } from "react";
import { GenericProps } from "../../helpers/types";
import { InformationPage, InformationPageType } from "../../helpers/helpers";
import { useLocation } from "react-router-dom";
import IndiaCorona from "./india/IndiaCorona";
import StayInIndia from "./india/StayInIndia";
import TravelToIndia from "./india/TravelToIndia";
import IndiaWedding from "./india/IndiaWedding";

interface InformationProps extends GenericProps {
  page: InformationPage;
}

const Information = (props: InformationProps) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const renderCorrectPage = () => {
    switch (page.type) {
      case InformationPageType.indiaCorona:
        return <IndiaCorona />;
      case InformationPageType.indiaStay:
        return <StayInIndia />;
      case InformationPageType.indiaTravel:
        return <TravelToIndia />;
      case InformationPageType.indiaWedding:
        return <IndiaWedding />;
    }
  };
  const { page } = props;

  return (
    <section className="flex flex-col justify-start md:ml-0 lg:ml-96 mt-32 md:mt-96 lg:mt-14 min-h-screen">
      <div className="pl-5 md:pl-10 lg:pl-32 pr-5 lg:pr-10 flex flex-col text-center pt-32 md:pt-0">
        {renderCorrectPage()}
      </div>
    </section>
  );
};

export default Information;
