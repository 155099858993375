import React from "react";
import { Link } from "react-router-dom";
import { GenericProps } from "../../helpers/types";
import JosefinSlabText from "../text/JosefinSlabText";

interface CardProps extends GenericProps {
  header: string;
  image: any;
  link: string;
}

const Card = (props: CardProps) => {
  return (
    <Link
      to={props.link}
      className={
        "flex flex-col justify-center lg:flex-row h-32 border overflow-hidden shadow-lg bg-white w-full md:w-1/4 lg:w-full p-3 rounded-2xl m-3 " +
        props.className
      }
    >
      <img
        alt={"card illustration"}
        className="w-1/3 mx-auto lg:mx-0"
        src={props.image}
      />
      <div className="p-4 flex flex-col justify-center flex-wrap text-center align-middle">
        <JosefinSlabText className="font-bold text-sm lg:text-xl">
          {props.header}
        </JosefinSlabText>
      </div>
    </Link>
  );
};

export default Card;
