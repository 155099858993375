import React from "react";
import MenuButton from "./MenuButton";
import {
  AvailableMenuItems,
  getLanguageName,
  MenuItem,
} from "../../helpers/helpers";
import MenuText from "../text/MenuText";
import { useIntl } from "react-intl";

interface HeaderProps {
  isSecondaryHeader?: boolean;
  handleChangeLanguage: (existingText: string) => void;
}

const Header = (props: HeaderProps) => {
  const { isSecondaryHeader, handleChangeLanguage } = props;
  const locale = useIntl().locale;
  const buttonText = getLanguageName(locale);
  console.log("Header, Line 20 buttonText: ", buttonText);
  return (
    <div className="flex justify-between fixed bg-mainBackground w-screen">
      <div className="flex flex-row justify-between">
        {!isSecondaryHeader &&
          AvailableMenuItems.getAllAsArray().map((menuItem: MenuItem) => (
            <MenuButton menuItem={menuItem} key={menuItem.url} />
          ))}
        {isSecondaryHeader && (
          <MenuButton menuItem={AvailableMenuItems.home} isNotHashRouter />
        )}
      </div>
      <div className="flex flex-col justify-start">
        <button
          onClick={() => handleChangeLanguage(locale)}
          className="inline-flex items-center m-1 p-2 rounded-md shadow-sm text-sm font-normal text-white bg-gray-800 hover:bg-gray-600"
        >
          <MenuText>{buttonText}</MenuText>
        </button>
      </div>
    </div>
  );
};

export default Header;
