import React from "react";
import { AvailableInformationPages } from "../../../helpers/helpers";
import GreatVibesText from "../../../components/text/GreatVibesText";
import NormalText from "../../../components/text/NormalText";

const IndiaCorona = () => {
  const page = AvailableInformationPages.coronaInIndia;
  return (
    <>
      <GreatVibesText>{page.textKeyPrefix + page.title}</GreatVibesText>
      <div className="text-justify">
        <NormalText key={"line1"}>{page.textKeyPrefix + "line1"}</NormalText>
        <NormalText key={"line2"}>{page.textKeyPrefix + "line2"}</NormalText>
        <NormalText key={"line3"}>{page.textKeyPrefix + "line3"}</NormalText>
        <NormalText key={"line4"}>{page.textKeyPrefix + "line4"}</NormalText>
        <NormalText key={"line5"}>{page.textKeyPrefix + "line5"}</NormalText>
      </div>
    </>
  );
};

export default IndiaCorona;
