import React from "react";
import { GenericProps } from "../../helpers/types";
import MenuText from "../text/MenuText";
import { MenuItem } from "../../helpers/helpers";
import { NavHashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";

interface MenuButtonProps extends GenericProps {
  menuItem: MenuItem;
  isNotHashRouter?: boolean;
}

const MenuButton = (props: MenuButtonProps) => {
  const { menuItem, isNotHashRouter } = props;
  if (isNotHashRouter) {
    return (
      <NavLink
        className="m-0 p-2 flex hover:bg-mainRed hover:text-white text-mainRed"
        activeClassName="bg-mainRed text-mainBackground"
        to={menuItem.baseUrl ? menuItem.baseUrl : "/"}
      >
        <MenuText className={"text-xl "}>{menuItem.title}</MenuText>
      </NavLink>
    );
  }
  return (
    <NavHashLink
      smooth
      className="m-0 p-2 flex hover:bg-mainRed hover:text-white text-mainRed"
      activeClassName="bg-mainRed text-mainBackground"
      to={menuItem.url}
    >
      <MenuText className={"text-xl "}>{menuItem.title}</MenuText>
    </NavHashLink>
  );
};

export default MenuButton;
