import React from "react";
import { GenericProps } from "../../helpers/types";
import { FormattedMessage } from "react-intl";

const GreatVibesText = (props: GenericProps) => {
  return (
    <h2
      className={
        "font-GreatVibes text-mainRed tracking-tight text-4xl md:text-5xl subpixel-antialiased" +
        (props.className ? " " + props.className : "")
      }
      style={{ textRendering: "geometricPrecision" }}
    >
      {props.children ? <FormattedMessage id={props.children} /> : null}
    </h2>
  );
};

export default GreatVibesText;
