import React from "react";
import { GenericProps } from "../../helpers/types";
import JosefinSlabText from "./JosefinSlabText";

const MenuText = (props: GenericProps) => {
  return (
    <JosefinSlabText className={props.className ? props.className : ""}>
      {props.children}
    </JosefinSlabText>
  );
};

export default MenuText;
