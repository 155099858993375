import { LOCALES } from "../i18n/messages";

export type MenuItem = {
  name: string;
  title: string;
  url: string;
  baseUrl?: string;
};

export enum Country {
  india,
  germany,
}

export enum InformationPageType {
  indiaTravel,
  indiaStay,
  indiaWedding,
  indiaCorona,
}

export interface InformationPage extends MenuItem {
  textKeyPrefix: string;
  country: Country;
  type: InformationPageType;
}

export class AvailableMenuItems {
  static home: MenuItem = {
    name: "home",
    title: "menu.home",
    url: "#home",
    baseUrl: "/#home",
  };
  static saveTheDate: MenuItem = {
    name: "date",
    title: "menu.save_the_date",
    url: "#date",
  };
  static india: MenuItem = {
    name: "india",
    title: "menu.india",
    url: "#india",
  };
  static germany: MenuItem = {
    name: "germany",
    title: "menu.germany",
    url: "#germany",
  };

  public static getAllAsArray(): MenuItem[] {
    return [
      AvailableMenuItems.home,
      AvailableMenuItems.saveTheDate,
      AvailableMenuItems.india,
      AvailableMenuItems.germany,
    ];
  }
}

export const getLanguageName = (languageCode: string): string => {
  if (languageCode && languageCode.toLowerCase() === "de") {
    return "languages.en";
  } else {
    return "languages.de";
  }
};

export const findLocaleName = (language: string | undefined): string => {
  if (!language) {
    return LOCALES.ENGLISH;
  }
  if (language.includes("de")) {
    return LOCALES.GERMAN;
  } else {
    return LOCALES.ENGLISH;
  }
};

export class AvailableInformationPages {
  static travelToIndia: InformationPage = {
    textKeyPrefix: "information.india.travel.",
    country: Country.india,
    url: "/travel-to-india",
    name: "travel",
    title: "title",
    type: InformationPageType.indiaTravel,
  };

  static whereToStay: InformationPage = {
    textKeyPrefix: "information.india.stay.",
    country: Country.india,
    url: "/stay-in-india",
    name: "stay",
    title: "title",
    type: InformationPageType.indiaStay,
  };
  static indianWedding: InformationPage = {
    textKeyPrefix: "information.india.wedding.",
    country: Country.india,
    url: "/wedding-in-india",
    name: "wedding",
    title: "title",
    type: InformationPageType.indiaWedding,
  };

  static coronaInIndia: InformationPage = {
    textKeyPrefix: "information.india.corona.",
    country: Country.india,
    url: "/corona-in-india",
    name: "coronaIndia",
    title: "title",
    type: InformationPageType.indiaCorona,
  };

  public static getAllAsArray(): InformationPage[] {
    return [
      AvailableInformationPages.travelToIndia,
      AvailableInformationPages.whereToStay,
      AvailableInformationPages.indianWedding,
      AvailableInformationPages.coronaInIndia,
    ];
  }
}
